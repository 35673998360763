const localStorageMethods = {
  SetCartId(cartId) {
    localStorage.setItem('cartId', cartId);
  },
  GetCartId() {
    const cartId = localStorage.getItem('cartId');
    return cartId ? JSON.parse(localStorage.getItem('cartId')) : '';
  },
};
export default localStorageMethods;
