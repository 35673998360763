/* eslint-disable */

import { createApp } from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/main.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import utils from './utils/utils';
import localStorageMethods from './utils/localStorage';

import auth from './services/auth.service';
import axios from 'axios';
import authHeader from './services/auth-header';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

import moment from 'moment';
import initFacebookSdk from './utils/init-facebook-sdk';

var baseURL =  process.env.VUE_APP_BASE_URL;
const app = createApp(App);

app.component('VueMultiselect', VueMultiselect);
app.component('VueDatePicker', VueDatePicker);

app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$localStorageMethods = localStorageMethods;
app.config.globalProperties.$auth = auth;
app.config.globalProperties.$moment = moment;

const axiosConfig = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: authHeader(),
  },
});
axiosConfig.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    const originalConfig = err.config;
    if (
      originalConfig.url !==  `${baseURL}Auth/login` &&
      originalConfig.url !== `${baseURL}Account/register` &&
      err.response
    ) {
      if (err.response.status === 401) {
        return store.dispatch('auth/refresh').then(() => {
          originalConfig.headers.Authorization = authHeader();
          axiosConfig.defaults.headers.Authorization = authHeader();
          return axiosConfig(originalConfig);
        });
      }
    }
    return Promise.reject(err);
  }
);

app.config.globalProperties.$axios = {
  ...axiosConfig,
};

initFacebookSdk();
app.mount('#app');
