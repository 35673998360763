<!-- eslint-disable vue/no-mutating-props -->
<template>
  <a
    :href="`/produs/${$utils.StringToSlug(product.Title)}/${product.Id}`"
    :title="`Vezi produsul ${product.Title}`"
    :id="`${$utils.StringToSlug(product.Title)}`"
  >
    <div class="card">
      <img :src="product.ImgBase64" class="card-img-top" alt="..." @error="$utils.ShowDefaultImage"/>
      <!-- <div
        class="money-saved-badge"
        v-if="product.PriceDetails && product.PriceDetails.MoneySaved && !product.OutOfStock"
      >
        <span class="text-button-02 text-white fw-medium">
          -{{ product.PriceDetails.MoneySaved }} lei
        </span>
      </div> -->
      <div class="card-body">
        <!-- <div class="rating">
          <div class="row align-items-center mt-2 justify-content-center d-none d-md-flex">
            <div class="col-auto">
              <img
                :src="ShowRating(product.AverageRating)"
                alt="Product averate rating"
                width="110"
                height="20"
              />
            </div>
            <div
              class="col-2 text-button-03 text-neutral-07 fw-bold pe-0"
              style="padding-left: 2px"
            >
              ({{ product.NumberOfReviews }})
            </div>

            <div
              @click.prevent
              class="col-auto pointer pe-0"
              style="padding-left: 15px"
              v-if="Object.hasOwn(product, 'IsFavorit') && this.$store.state.auth.user"
            >
              <div
                @mouseover="product.Hover = true"
                @mouseleave="product.Hover = false"
                @click="ChangeFavoriteStatus"
              >
                <div v-if="product.IsFavorit || product.Hover">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.59973 4.74111L10.0001 5.27624L10.4004 4.74111C11.1522 3.73632 12.3567 3.08325 13.7001 3.08325C15.9799 3.08325 17.8334 4.94039 17.8334 7.24159C17.8334 8.17887 17.6839 9.04367 17.4243 9.846L17.4233 9.84926C16.8008 11.8193 15.524 13.41 14.1419 14.5978C12.7576 15.7875 11.2963 16.5482 10.3557 16.8682L10.3557 16.8682L10.3503 16.8701C10.2788 16.8953 10.1518 16.9166 10.0001 16.9166C9.84833 16.9166 9.72133 16.8953 9.64983 16.8701L9.64984 16.8701L9.64447 16.8682C8.70384 16.5482 7.24258 15.7875 5.85827 14.5978C4.47616 13.41 3.19938 11.8193 2.57684 9.84926L2.57686 9.84926L2.5758 9.84601C2.31622 9.04367 2.16675 8.17887 2.16675 7.24159C2.16675 4.94039 4.02022 3.08325 6.30008 3.08325C7.64351 3.08325 8.84799 3.73632 9.59973 4.74111Z"
                      fill="#D6366C"
                      stroke="#D6366C"
                    />
                  </svg>
                </div>
                <div v-else>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2744 16.6318L10.2744 16.6318L10.2671 16.6343C10.2307 16.6472 10.134 16.6666 10.0001 16.6666C9.8662 16.6666 9.76946 16.6472 9.73303 16.6343L9.73304 16.6343L9.72572 16.6318C8.62503 16.2561 6.77274 15.2516 5.20556 13.6459C3.64743 12.0494 2.41675 9.90877 2.41675 7.24159C2.41675 5.07729 4.15946 3.33325 6.30008 3.33325C7.56822 3.33325 8.69096 3.94508 9.39796 4.89708C9.53945 5.0876 9.76277 5.19992 10.0001 5.19992C10.2374 5.19992 10.4607 5.0876 10.6022 4.89708C11.3087 3.9457 12.4395 3.33325 13.7001 3.33325C15.8407 3.33325 17.5834 5.07729 17.5834 7.24159C17.5834 9.90877 16.3527 12.0494 14.7946 13.6459C13.2274 15.2516 11.3751 16.2561 10.2744 16.6318Z"
                      stroke="#D6366C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mt-2 justify-content-center d-md-none ms-0 me-0">
            <div class="col-auto pe-0 ps-2">
              <img
                src="../../assets/images/icons/filled-star-20x20.svg"
                alt="..."
                width="20"
                height="20"
              />
            </div>
            <div class="col-auto text-button-03 text-neutral-07 fw-bold pe-0 ps-1">
              {{ product.NumberOfReviews }}
            </div>
            <div
              class="col"
              v-if="Object.hasOwn(product, 'IsFavorit') && this.$store.state.auth.user"
            >
              <div v-if="product.IsFavorit" class="text-end">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.59973 4.74111L10.0001 5.27624L10.4004 4.74111C11.1522 3.73632 12.3567 3.08325 13.7001 3.08325C15.9799 3.08325 17.8334 4.94039 17.8334 7.24159C17.8334 8.17887 17.6839 9.04367 17.4243 9.846L17.4233 9.84926C16.8008 11.8193 15.524 13.41 14.1419 14.5978C12.7576 15.7875 11.2963 16.5482 10.3557 16.8682L10.3557 16.8682L10.3503 16.8701C10.2788 16.8953 10.1518 16.9166 10.0001 16.9166C9.84833 16.9166 9.72133 16.8953 9.64983 16.8701L9.64984 16.8701L9.64447 16.8682C8.70384 16.5482 7.24258 15.7875 5.85827 14.5978C4.47616 13.41 3.19938 11.8193 2.57684 9.84926L2.57686 9.84926L2.5758 9.84601C2.31622 9.04367 2.16675 8.17887 2.16675 7.24159C2.16675 4.94039 4.02022 3.08325 6.30008 3.08325C7.64351 3.08325 8.84799 3.73632 9.59973 4.74111Z"
                    fill="#D6366C"
                    stroke="#D6366C"
                  />
                </svg>
              </div>
              <div v-else class="text-end">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2744 16.6318L10.2744 16.6318L10.2671 16.6343C10.2307 16.6472 10.134 16.6666 10.0001 16.6666C9.8662 16.6666 9.76946 16.6472 9.73303 16.6343L9.73304 16.6343L9.72572 16.6318C8.62503 16.2561 6.77274 15.2516 5.20556 13.6459C3.64743 12.0494 2.41675 9.90877 2.41675 7.24159C2.41675 5.07729 4.15946 3.33325 6.30008 3.33325C7.56822 3.33325 8.69096 3.94508 9.39796 4.89708C9.53945 5.0876 9.76277 5.19992 10.0001 5.19992C10.2374 5.19992 10.4607 5.0876 10.6022 4.89708C11.3087 3.9457 12.4395 3.33325 13.7001 3.33325C15.8407 3.33325 17.5834 5.07729 17.5834 7.24159C17.5834 9.90877 16.3527 12.0494 14.7946 13.6459C13.2274 15.2516 11.3751 16.2561 10.2744 16.6318Z"
                    stroke="#D6366C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div> -->
        <div class="category">{{ product.CategoryName }}</div>
        <div class="heading-07 text-neutral-08 mb-2 pb-1 title-height" :title="product.Title">
          {{ product.Title }}
        </div>
        <div>
          <div
            class="paragraph-03 text-neutral-07 pb-1 description-height"
            v-if="product.Description"
            :title="product.Description"
          >
            {{ this.$utils.EllipsifyWords(product.Description, 11) }}
          </div>
          <div v-else class="paragraph-03 pb-1 description-height"></div>
          <div v-if="!product.IsNotAvailable && !product.OutOfStock">
            <div
              class="row align-items-baseline"
              v-if="product.PriceDetails && product.PriceDetails.OnSalePrice > 0"
            >
              <div class="col pe-0">
                <span class="heading-05 text-neutral-08 me-2">
                  {{
                    $utils.DisplayCalculatedProductPrice(
                      product.PriceDetails.OnSalePrice,
                      product.Amount
                    )
                  }}
                </span>
                <span class="heading-08 text-neutral-07">lei</span>
              </div>
              <div class="col-auto ps-0">
                <span class="text-neutral-08 me-1 original-price">
                  {{
                    $utils.DisplayCalculatedProductPrice(
                      product.PriceDetails.RegularPrice,
                      product.Amount
                    )
                  }}
                </span>
                <span class="paragraph-03 text-danger-05">lei</span>
              </div>
            </div>
            <div v-else>
              <span class="heading-05 text-neutral-08 me-2" v-if="product.PriceDetails">
                {{
                  $utils.DisplayCalculatedProductPrice(
                    product.PriceDetails.RegularPrice,
                    product.Amount
                  )
                }}
              </span>
              <span class="heading-08 text-neutral-07">lei</span>
            </div>
          </div>
          <div v-else class="heading-05 text-neutral-08 me-2" style="height: 35px"></div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
// import RatingComponent from '../General/RatingComponent.vue';

export default {
  name: 'DisplayProduct',
  props: {
    product: {},
  },
  components: {
    // RatingComponent,
  },
  methods: {
    ShowRating(rating) {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`@/assets/images/rating/${Math.ceil(rating)}-stars.webp`);
    },
    ShowThumbnail(imageBase64) {
      if (!imageBase64) {
        // eslint-disable-next-line global-require
        return require('@/assets/images/default-thumbnail.svg');
      }
      return imageBase64;
    },
    ChangeFavoriteStatus() {
      this.$emit('change', this.product);
    },
  },
};
</script>
<style scoped>
.out-of-stock {
  color: var(--secondary-accents-danger-05, #dd2531) !important;
}
@media (max-width: 576px) {
  .heading-05 {
    font-size: 20px;
  }
  .heading-08 {
    font-size: 14px;
  }
}
.description-height {
  height: 46px;
  overflow: hidden;
}

.title-height {
  height: 55px;
  overflow: hidden;
}

/* @media (max-width: 768px) {
  .description-height {
    height: 67px;
    overflow: hidden;
  }
} */

.money-saved-badge {
  background: linear-gradient(139deg, #dd2531 12.55%, #b11e27 88.32%);
  width: 136px;
  height: 35.477px;
  transform: rotate(45deg);
  flex-shrink: 0;
  position: absolute;
  top: 10px;
  right: -39px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.category {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.2em;
  color: var(--primary-5);
  text-transform: uppercase;
  margin-bottom: 5px;
  height: 18px;
}

.card-body {
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}

.rating {
  height: 40px;
  position: absolute;
  top: -39px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 65%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) -5%, #ffffff 100%);
  border-radius: 20px 20px 0px 0px;
}

@media (min-width: 768px) {
  .rating {
    max-width: 220px;
    border-radius: 24px 24px 0px 0px;
  }
}

.card {
  border: 0px;
  position: relative;
  overflow: hidden;
}
.card-img-top {
  height: 100%;
  height: 20vh;
  object-fit: cover;
  border-radius: 12px;
}

@media (min-width: 992px) {
  .card-img-top {
    height: 280px;
  }
}

.original-price {
  color: var(--danger-5) !important;
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
  font-weight: 500;
  line-height: 25px;
  font-size: 15px;
}

@media (min-width: 576px) {
  .original-price {
    font-size: 20px;
  }
}
</style>
