<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="add-to-cart-section">
    <div v-if="product.OutOfStock == false && product.IsNotAvailable == false">
      <div class="row d-none d-md-flex" v-if="product.ShowCartButtons">
        <div class="col-auto pe-0" style="width: 100px" v-if="!product.HasConfigurations">
          <div class="input-group right h-100">
            <input
              type="number"
              class="form-control product-amount-input"
              v-model="product.Amount"
            />
            <span class="input-group-text">
              <img
                alt="..."
                src="../../assets/images/icons/pink-chevron-up-20x20.svg"
                @click="product.Amount++"
              />
              <img
                alt="..."
                src="../../assets/images/icons/pink-chevron-down-20x20.svg"
                :class="{ disabled: product.Amount < 2 }"
                @click="product.Amount > 1 ? product.Amount-- : ''"
              />
            </span>
          </div>
        </div>
        <div class="col ps-3">
          <router-link
            v-if="product.HasConfigurations"
            :to="{
              name: 'Product',
              params: {
                productName: $utils.StringToSlug(product.Title),
                productIdParam: product.Id,
              },
            }"
            type="button"
            class="button btn-medium btn-primary-outlined fw-semibold w-100 text-center"
          >
            <img src="../../assets/images/icons/pink-cart-bold-20x20.svg" alt="..." />
            Configurează
          </router-link>
          <button
            v-else
            @click="product.Amount > 0 ? AddProductToCart() : ''"
            :class="{
              'disabled-btn btn-disabled-solid button btn-medium fw-semibold w-100':
                product.Amount <= 0,
              'button btn-medium btn-primary-outlined fw-semibold w-100': product.Amount > 0,
            }"
            type="button"
          >
            <img src="../../assets/images/icons/pink-cart-bold-20x20.svg" alt="..." />
            Adaugă în coș
          </button>
        </div>
      </div>
      <div class="row d-md-none align-items-center">
        <div class="col-auto pe-0" v-if="!product.HasConfigurations">
          <div class="row align-items-center">
            <div class="col-auto pe-0">
              <button
                type="button"
                class="button table-btn"
                :class="{ disabled: product.Amount == 1 }"
                @click="product.Amount > 1 ? product.Amount-- : ''"
              >
                <img src="../../assets/images/icons/table-minus-20x20.svg" alt="..." />
              </button>
            </div>
            <div class="col">
              <div class="text-neutral-06 fw-semibold text-center" style="width: 19px">
                {{ product.Amount }}
              </div>
            </div>
            <div class="col-auto ps-1">
              <button type="button" class="button table-btn" @click="product.Amount++">
                <img src="../../assets/images/icons/table-plus-20x20.svg" alt="..." />
              </button>
            </div>
          </div>
        </div>
        <div class="col mobile-btn">
          <router-link
            v-if="product.HasConfigurations"
            :to="{
              name: 'Product',
              params: {
                productName: $utils.StringToSlug(product.Title),
                productIdParam: product.Id,
              },
            }"
            type="button"
            class="button btn-sm btn-primary-outlined fw-semibold text-center w-100"
          >
            <img src="../../assets/images/icons/add-to-cart-mobile-icon.svg" alt="..." />
            Configurează
          </router-link>
          <button
            v-else
            @click="product.Amount > 0 ? AddProductToCart() : ''"
            :class="{ 'disabled-btn': product.Amount <= 0 }"
            type="button"
            class="button btn-sm btn-primary-outlined fw-semibold w-100"
          >
            <img src="../../assets/images/icons/add-to-cart-mobile-icon.svg" alt="..." />
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row d-none d-md-flex" v-if="product.ShowCartButtons">
        <div class="col ps-3">
          <router-link
            :to="{
              name: 'Product',
              params: {
                productName: $utils.StringToSlug(product.Title),
                productIdParam: product.Id,
              },
            }"
            type="button"
            class="button btn-medium btn-primary-outlined fw-semibold w-100 text-center"
          >
            <div v-if="product.OutOfStock">Indisponibil</div>
            <div v-else>Produsul nu este valabil</div>
          </router-link>
        </div>
      </div>
      <div class="d-md-none">
        <router-link
          :to="{
            name: 'Product',
            params: {
              productName: $utils.StringToSlug(product.Title),
              productIdParam: product.Id,
            },
          }"
          type="button"
          class="button btn-sm btn-primary-outlined fw-semibold text-center w-100 pt-1 pb-1"
        >
          <div v-if="product.OutOfStock" style="font-size: 12px">Indisponibil</div>
          <div v-else style="font-size: 12px">Produsul nu este valabil</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    product: Object,
    user: Object,
  },
  emits: ['prod-added-success-in-cart', 'close-pop-up'],
  components: {},
  name: 'add-to-cart-component',
  data() {
    return {};
  },
  methods: {
    AddProductToCart() {
      this.$emit('close-pop-up');
      this.$store.state.loader = true;
      const addProduct = {
        ProductId: this.product.Id,
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
        Quantity: this.product.Amount,
        ...(this.$localStorageMethods.GetCartId()
          ? { CartId: this.$localStorageMethods.GetCartId() }
          : ''),
      };
      this.$axios
        .post(`/Order/${this.$utils.ApiNameByCurrentUser('addToCart')}`, addProduct)
        .then((response) => {
          this.$localStorageMethods.SetCartId(response.data);
          window.dispatchEvent(new Event('CartChangedEventBackend'));
          this.$store.state.loader = false;
          this.$emit('prod-added-success-in-cart');
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
  },
};
</script>
<style scoped>
.table-btn {
  padding: 4px;
  border-radius: 4px !important;
}
@media (max-width: 767px) {
  .mobile-btn .btn-primary-outlined {
    padding: 4px 8px;
  }
  .add-to-cart-section {
    height: 50px;
  }
}
@media (min-width: 768px) {
  .add-to-cart-section {
    height: 47.88px;
  }
}
</style>
