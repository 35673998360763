<template>
  <div class="top-products pt-2 pt-sm-0">
    <div class="bg"></div>
    <div class="content text-center pt-4 pt-xxl-5 mt-3 mt-xxl-5">
      <div class="page-title text-neutral-07">
        Descoperă cele mai <br />
        delicioase prăjituri
      </div>
      <div class="social-media-section mt-sm-4 mb-sm-4 mt-2 mb-2">
        <div class="row align-items-center justify-content-evenly">
          <div class="col-auto">
            <a href="https://www.facebook.com/delicitate" target="_blank" rel="noopener noreferrer">
              <img
                src="../../assets/images/icons/gray-fb-icon.svg"
                alt="..."
                width="48"
                height="48"
              />
            </a>
          </div>
          <div class="col-auto">
            <a
              href="https://www.instagram.com/delicitate"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="../../assets/images/icons/gray-insta-icon.svg"
                alt="..."
                width="48"
                height="48"
              />
            </a>
          </div>
          <div class="col-auto">
            <a
              href="https://api.whatsapp.com/send/?phone=40746298695&text&app_absent=0&lang=ro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="../../assets/images/icons/gray-whatsapp-icon.svg"
                alt="..."
                width="48"
                height="48"
              />
            </a>
          </div>
          <div class="col-auto">
            <a :href="`mailto:${contactEmail}`" rel="noopener noreferrer">
              <img
                src="../../assets/images/icons/gray-mail-icon.svg"
                alt="..."
                width="48"
                height="48"
              />
            </a>
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'Products' }" class="button btn-medium-wider btn-primary-solid">
        Ofertă de sărbători
      </router-link>
    </div>
  </div>

  <div class="container categories" style="margin-bottom: 80px">
    <carousel
      :items-to-show="carouselCategoriesCount"
      :wrap-around="true"
      :autoplay="2000"
      @mouseover="autoplay = 2000"
      @mouseleave="autoplay = 2000"
    >
      <slide
        v-for="(category, index) in placeHolderLoader.Categories
          ? Math.ceil(carouselCategoriesCount)
          : categories"
        :key="index"
      >
        <router-link
          v-if="!placeHolderLoader.Categories"
          :to="{
            name: 'Products',
            params: {
              categoryName: $utils.StringToSlug(category.Name),
              categoryId: category.Id,
            },
          }"
        >
          <div class="prod-categories-card">
            <div>
              <img :src="GetAttachmentName(category.Img)" alt="..." class="icon" />
              <div class="heading-07 text-neutral-08">
                {{ category.Name }}
              </div>
            </div>
          </div>
        </router-link>
        <div class="loader-card prod-categories-card" v-else></div>
      </slide>

      <template #addons v-if="carouselCategoriesCount >= 3">
        <navigation>
          <template #prev>
            <img
              src="../../assets/images/icons/carousel-arrow-left-20x20.svg"
              alt="..."
              width="20"
              height="20"
            />
          </template>
          <template #next>
            <img
              src="../../assets/images/icons/carousel-arrow-right-20x20.svg"
              alt="..."
              width="20"
              height="20"
            />
          </template>
        </navigation>
        <pagination />
      </template>
    </carousel>
  </div>

  <div class="heading-04 text-secondary-05 text-center mb-4 pb-1">Produse recomandate</div>
  <section class="recommended-products-section">
    <div class="container">
      <div class="row mt-3" v-if="placeHolderLoader.Products">
        <div class="col-6 col-lg-4 col-xl-3 mb-3 pb-1" v-for="(item, index) in 8" :key="index">
          <product-loader-component />
        </div>
      </div>
      <div class="row mt-3" v-else>
        <div
          class="col-6 col-lg-4 col-xl-3 mb-3 pb-1"
          v-for="(product, index) in products"
          :key="index"
          @mouseover="product.ShowCartButtons = true"
          @mouseleave="product.ShowCartButtons = false"
        >
          <DisplayProduct :product="product" @change="ChangeFavoriteStatus" />
          <add-to-cart-component
            :product="product"
            :user="currentUser"
            @prod-added-success-in-cart="ShowSuccessPopUp"
            @close-pop-up="CloseSuccessPopUp()"
          />
        </div>
      </div>
      <div class="text-center">
        <router-link class="button btn-medium-wider btn-primary-solid" :to="{ name: 'Products' }">
          Ofertă de sărbători
        </router-link>
      </div>
    </div>
  </section>

  <section
    class="recent-articles-section"
    v-if="!placeHolderLoader.Articles && articles && articles.length > 0"
  >
    <div class="container-xl pt-5 pt-md-0 pb-5 pb-md-0 ps-5 pe-5 pe-xl-0 ps-xl-0">
      <div class="heading-01 text-white text-center pb-1 pt-5 pt-md-0">Articole recente</div>
      <div class="title-border mb-3 mt-4"></div>
      <div class="title-border ms-auto me-auto pb-5" style="max-width: 42vw"></div>
      <div class="row align-items-center mt-5 pt-5" v-if="!placeHolderLoader.Articles">
        <div
          class="col-md-6 col-lg-4 mb-articles"
          v-for="(article, index) in articles"
          :key="index"
        >
          <div class="article card">
            <div class="card-img-box">
              <div class="position-relative h-100">
                <img
                  :src="article.ImgBase64"
                  class="card-img-top"
                  alt="..."
                  width="110"
                  height="20"
                  @error="$utils.ShowDefaultImage"
                />
                <div class="date-box">
                  {{ article.Date }}
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="article-mobile-height">
                <div class="other-info">
                  <div class="heading-06 text-white" :title="article.Title">
                    {{ this.$utils.Ellipsify(article.Title, 45) }}
                  </div>
                  <div class="text-button-02 text-white mt-2" v-if="article.CategoryName">
                    <img
                      src="../../assets/images/icons/white-tag-20x20.svg"
                      class="me-2"
                      alt="..."
                      width="20"
                      height="20"
                    />
                    {{ article.CategoryName }}
                  </div>
                </div>
                <div class="row align-items-center mt-3">
                  <div class="col">
                    <div class="paragraph-02 text-neutral-02" :title="article.ShortDescription">
                      {{ this.$utils.Ellipsify(article.ShortDescription, 60) }}
                    </div>
                  </div>
                  <div class="col-auto">
                    <router-link
                      class="go-to-article-btn"
                      :to="{
                        name: 'Article',
                        params: {
                          articleName: $utils.StringToSlug(article.Title),
                          articolIdParam: article.Id,
                        },
                      }"
                      target="_blank"
                    >
                      <img
                        src="../../assets/images/icons/article-arrow-20x20.svg"
                        alt="..."
                        width="21"
                        height="22"
                      />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-5 pt-5" v-if="placeHolderLoader.Articles">
        <div class="col-md-6 col-lg-4 col-xl-3 mb-3 pb-1" v-for="(item, index) in 8" :key="index">
          <div class="loader-card shadow-sm" style="height: 350px !important"></div>
        </div>
      </div>
    </div>
  </section>

  <div class="review-sections" ref="reviewSectionOffscreen">
    <div class="container" style="max-width: 1615px">
      <carousel
        :items-to-show="carouselReviewsCount"
        class="mt-sm-5 pt-sm-5 reviews-carousel"
        @mouseover="autoplay = 0"
        @mouseleave="autoplay = 2500"
        :wrap-around="true"
      >
        <slide v-for="(review, index) in placeHolderLoader.Reviews ? 3 : reviews" :key="index">
          <div class="card review w-100" v-if="!placeHolderLoader.Reviews">
            <div class="card-body">
              <div class="text-button-01 text-neutral-08">{{ review.UserName }}</div>
              <div class="row justify-content-center rating-mb">
                <div class="col-auto">
                  <img
                    :src="ShowRating(review.Rating)"
                    :alt="`${review.UserName} review`"
                    width="110"
                    height="20"
                  />
                </div>
                <div class="col-auto text-button-01 text-neutral-07 ps-0">{{ review.Rating }}</div>
              </div>
              <div
                class="paragraph-04 text-neutral-08 comment-height other"
                :title="review.Comment"
                v-if="review.Comment"
              >
                {{ this.$utils.Ellipsify(review.Comment, 120) }}
              </div>
              <div class="paragraph-03 text-neutral-08 comment-height main">
                {{ review.Comment }}
              </div>
            </div>
          </div>
          <div
            class="loader-card review shadow-sm mt-5 mb-5"
            style="width: 90%; height: 250px !important"
            v-else
          ></div>
        </slide>

        <template #addons>
          <navigation v-if="carouselCategoriesCount >= 3">
            <template #prev>
              <img
                src="../../assets/images/icons/carousel-arrow-left-20x20.svg"
                alt="..."
                width="20"
                height="20"
              />
            </template>
            <template #next>
              <img
                src="../../assets/images/icons/carousel-arrow-right-20x20.svg"
                alt="..."
                width="20"
                height="20"
              />
            </template>
          </navigation>
          <pagination v-if="carouselCategoriesCount >= 3" />
        </template>
      </carousel>
    </div>
  </div>

  <div class="heading-01 text-secondary-05 text-center" style="margin-top: 135px">Locații</div>
  <div>
    <section class="locations-section m-0" ref="locationsSectionOffscreen">
      <div class="container position-relative m-auto" style="z-index: 1">
        <div class="row justify-content-center mt-5 pt-2 me-0 ms-0 me-sm-2 ms-sm-2">
          <div
            class="col-xxl-4 col-md-6 mb-5 mb-md-0"
            v-for="(location, index) in locations"
            :key="index"
          >
            <div class="location card">
              <div class="card-body">
                <div class="name mb-2 pb-1">{{ location.Name }}</div>
                <div class="text-button-02 mb-1">{{ location.Description }}</div>
                <div
                  class="contact-box mt-2"
                  :style="[location.Contact.length == 0 ? 'border-width: 1px 0px 0px 0px;' : '']"
                  :class="{ 'mb-3': location.Contact.length > 0 }"
                >
                  <div
                    class="d-flex align-items-center justify-content-center"
                    :class="{
                      'mb-2':
                        location.Contact &&
                        location.Contact.length > 1 &&
                        index < location.Contact.length - 1,
                    }"
                    v-for="(contact, index) in location.Contact"
                    :key="index"
                  >
                    <img
                      src="../../assets/images/icons/pink-call-linear-20x20.svg"
                      class="me-2"
                      alt="..."
                    />
                    <a
                      :href="`tel:${contact}`"
                      class="text-button-01 text-primary-05"
                      rel="noopener noreferrer"
                    >
                      {{ contact }}
                    </a>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center paragraph-03"
                  :class="{ 'mb-2': programIndex < location.Program.length - 1 }"
                  v-for="(program, programIndex) in location.Program"
                  :key="programIndex"
                >
                  <span> {{ program.Day }}: &nbsp;</span>
                  <span class="fw-bold"> {{ program.Schedule }} </span>
                </div>
                <a
                  :href="`https://www.google.com/maps?q=${location.Latitude},${location.Longitude}`"
                  target="_blank"
                  class="icon"
                  :style="[
                    !location.Latitude || !location.Longitude
                      ? 'pointer-events: none !important'
                      : '',
                  ]"
                  rel="noopener noreferrer"
                >
                  <img
                    src="../../assets/images/icons/location-bold-20x20.svg"
                    alt="..."
                    width="20"
                    height="20"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <picture class="w-100 rounded-bg">
        <source
          v-for="(width, index) in imagesAndMaxWidthMapped"
          :key="index"
          :media="`(max-width: ${width}px)`"
          :srcset="
            require(`@/assets/images/home-locations-bg/home-locations-white-rounded-bg_${width}.webp`)
          "
        />
        <img
          src="../../assets/images/home-locations-bg/home-locations-white-rounded-bg_425.webp"
          alt="Locations bg"
        />
      </picture>
    </section>
  </div>

  <div
    class="row me-0 ms-lg-0 align-items-center justify-content-lg-end justify-content-center mt-5 mt-md-0"
    style="margin-bottom: 5vh; content-visibility: auto"
  >
    <div class="col-4 col-xxl-6 ps-0 d-none d-xl-block">
      <img
        src="../../assets/images/home-mailbox-bottom-img.webp"
        alt="..."
        loading="lazy"
        width="844"
        height="494"
      />
    </div>
    <div class="col-md-9 col-lg-10 col-xl-8 col-xxl-6 position-relative pe-0">
      <img
        src="../../assets/images/home-newsletter-bg.webp"
        class="float-end d-none d-lg-block"
        alt="..."
        loading="lazy"
        width="740"
        height="318"
      />
      <div class="card newsletter me-3 ms-3 me-md-0 ms-md-0">
        <div class="card-body">
          <div class="heading-06 text-white">
            Abonează-te la newsletter-ul nostru și stai la curent cu noutățile
          </div>
          <input type="text" class="form-control outlined center" placeholder="Adresa de email" />
          <button type="button" class="button btn-medium btn-primary-outlined w-100 fw-semibold">
            Abonare
          </button>
        </div>
      </div>
    </div>
  </div>
  <ChangePasswordModal :user="user" />

  <template v-if="showSuccessPopup && popUpComponent">
    <component
      :is="popUpComponent"
      @close-pop-up="CloseSuccessPopUp()"
      :showSuccessPopup="showSuccessPopup"
    />
  </template>
</template>

<script>
/* eslint-disable */

import '@/assets/placeholder-loader.css';

import DisplayProduct from '../../components/Product/DisplayProductComponent.vue';
import { markRaw } from 'vue';
import ProductAddedToCartPopUpComponent from '../../components/Product/ProductAddedToCartPopUpComponent.vue';

import ChangePasswordModal from '../../components/Auth/ChangePasswordModal.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import AddToCartComponent from '../../components/General/AddToCartComponent.vue';
import ProductLoaderComponent from '../../components/Product/ProductLoaderComponent.vue';

export default {
  name: 'HomePage',
  data() {
    return {
      showSuccessPopup: false,
      popUpComponent: null,
      imagesAndMaxWidthMapped: ['425', '768', '1024', '1366', '1920', '2560'],
      categories: [],
      articles: [],
      products: [],
      productsFilter: {
        PageSize: 8,
        PageNumber: 1,
      },
      productsPagination: {},
      reviews: [],
      reviewsFilter: {
        PageSize: 3,
        PageNumber: 1,
      },
      locations: [],
      user: '',
      carouselCategoriesCount: 2.5,
      carouselReviewsCount: 3,
      contactEmail: '',
      windowWidth: window.innerWidth,
      placeHolderLoader: {
        Products: false,
        Categories: false,
        Articles: false,
        Reviews: false,
      },
    };
  },
  components: {
    DisplayProduct,
    ChangePasswordModal,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    AddToCartComponent,
    ProductAddedToCartPopUpComponent,
    ProductLoaderComponent,
  },

  methods: {
    ShowSuccessPopUp() {
      this.popUpComponent = markRaw(ProductAddedToCartPopUpComponent);
      this.showSuccessPopup = true;
    },
    CloseSuccessPopUp() {
      this.showSuccessPopup = false;
      this.popUpComponent = null;
    },
    ShowRating(rating) {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`@/assets/images/rating/${Math.ceil(rating)}-stars.webp`);
    },
    GetAttachmentName(imgName) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/attachments/categories/${imgName}.svg`);
    },
    GetRecomandatedProducts() {
      this.placeHolderLoader.Products = true;
      const searchParams = {
        PageNumber: this.productsFilter.PageNumber,
        PageSize: this.productsFilter.PageSize,
        ...(this.$store.state.locationId ? { LocationId: this.$store.state.locationId } : ''),
        IsMobile: true,
      };
      this.$axios
        .get(`/Product/${this.$utils.ApiNameByCurrentUser('getRecomandations')}?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.products = response.data.Items;
          this.productsPagination = response.data.PageDetails;
          this.placeHolderLoader.Products = false;
        })
        .catch(() => {
          this.placeHolderLoader.Products = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetCategories() {
      this.placeHolderLoader.Categories = true;
      this.$axios
        .get('/Category/getAll')
        .then((response) => {
          this.categories = response.data;
          this.ChangeCarouselNumberItems();
          this.placeHolderLoader.Categories = false;
        })
        .catch(() => {
          this.placeHolderLoader.Categories = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetRecentArticles() {
      this.placeHolderLoader.Articles = true;
      this.$axios
        .get('/Blog/getRecentArticles')
        .then((response) => {
          this.articles = response.data;
          this.placeHolderLoader.Articles = false;
        })
        .catch(() => {
          this.placeHolderLoader.Articles = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetReviews() {
      this.placeHolderLoader.Reviews = true;
      const searchParams = {
        PageNumber: this.reviewsFilter.PageNumber,
        PageSize: this.reviewsFilter.PageSize,
      };
      this.$axios
        .get(`/Review/getTestimonials?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.reviews = response.data;
          this.placeHolderLoader.Reviews = false;
        })
        .catch(() => {
          this.placeHolderLoader.Reviews = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetLocatons() {
      //this.$store.state.loader = true;
      this.$axios
        .get('/Location/getHomeLocations')
        .then((response) => {
          this.locations = response.data;
          //this.$store.state.loader = false;
        })
        .catch(() => {
          //this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetContactEmail() {
      this.$axios
        .get('/Settings/getContactEmail')
        .then((response) => {
          this.contactEmail = response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    async ChangeFavoriteStatus(product) {
      if (this.currentUser) {
        this.$store.state.loader = true;
        if (product.IsFavorit === false) {
          await this.$axios.post(`/User/addToFavorite/${product.Id}`);
          // eslint-disable-next-line no-param-reassign
          product.IsFavorit = true;
        } else {
          await this.$axios.delete(
            // eslint-disable-next-line comma-dangle
            `/User/removeFromFavorite/${product.Id}`
          );
          // eslint-disable-next-line no-param-reassign
          product.IsFavorit = false;
        }
        this.$store.state.loader = false;
      }
    },
    SelectPage(nr) {
      this.reviewsFilter.PageNumber = nr;
      this.GetReviews();
    },
    ChangePassword() {
      this.user = {};
      if (this.$route.query.token && this.$route.query.email) {
        this.user.Code = this.$route.query.token.toString().replace(/\s/g, '+');
        this.user.Email = this.$route.query.email;
        document.addEventListener('DOMContentLoaded', () => {
          // eslint-disable-next-line no-undef
          $('#changePasswordModal').modal('show');
        });
      }
    },
    ChangeCarouselNumberItems(e) {
      const windowWidth = e ? e.target.innerWidth : window.innerWidth;
      switch (true) {
        case windowWidth > 992:
          this.carouselReviewsCount = 3;
          this.carouselCategoriesCount = 5;
          break;
        case windowWidth > 576 && windowWidth <= 992:
          this.carouselReviewsCount = 3;
          this.carouselCategoriesCount = 3;
          break;
        default:
          this.carouselReviewsCount = 1;
          this.carouselCategoriesCount = 2.5;
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.GetRecomandatedProducts();
    this.GetRecentArticles();
    this.GetReviews();
    this.GetLocatons();
    this.GetCategories();
    this.GetContactEmail();
  },
  mounted() {
    this.ChangePassword();
    window.addEventListener('resize', this.ChangeCarouselNumberItems);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Apply background image when the element comes into the viewport
          this.$refs.reviewSectionOffscreen.style.backgroundImage = `url(${require('@/assets/images/reviews-section-bg.webp')})`;
          this.$refs.locationsSectionOffscreen.style.backgroundImage = `url(${require('@/assets/images/home-map-locations.webp')})`;
          observer.unobserve(entry.target);
        }
      });
    });

    // Start observing the offscreen image element
    observer.observe(this.$refs.reviewSectionOffscreen);
    observer.observe(this.$refs.locationsSectionOffscreen);
  },
  unmounted() {
    window.removeEventListener('resize', this.ChangeCarouselNumberItems);
  },
  beforeRouteEnter(to, from, next) {
    let res = document.createElement('link');
    res.rel = 'preload';
    res.fetchpriority = 'high';
    res.as = 'image';
    res.type = 'image/webp';
    res.href = require('@/assets/images/compress_easter_home-top-products_1350x466.webp');
    document.head.appendChild(res);

    res = document.createElement('link');
    res.rel = 'preload';
    res.fetchpriority = 'high';
    res.as = 'image';
    res.type = 'image/webp';
    res.href = require('@/assets/images/recent-articles-bg.webp');
    document.head.appendChild(res);
    next();
  },
};
</script>

<style scoped>
.btn-medium-wider {
  padding: 12px 30px;
  gap: 4px;
  font-size: 14px;
}
@media (min-width: 576px) {
  .btn-medium-wider {
    padding: 12px 32px;
    gap: 4px;
    font-size: 14px;
  }
  .btn-medium {
    padding: 12px 16px;
    gap: 4px;
  }
}

.social-media-section img {
  cursor: pointer;
  filter: invert(75%) sepia(23%) saturate(166%) hue-rotate(150deg) brightness(83%) contrast(88%);
  transition: 0.2s;
}
.social-media-section a:hover img {
  filter: invert(36%) sepia(97%) saturate(660%) hue-rotate(298deg) brightness(84%) contrast(101%);
}
.social-media-section a:hover {
  border: 1px solid var(--primary-5);
}
.social-media-section a {
  display: block;
  border-radius: 50%;
  border: 1px solid transparent;
}

.categories.container {
  max-width: 1350px;
}
.prod-categories-card img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-bottom: 16px;
}
.prod-categories-card {
  border-radius: 8px;
  padding: 16px 12px;
  background-color: #f9fafa;
  border: 1px solid transparent;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 110px;
}
.prod-categories-card:hover {
  background-color: var(--neutral-2);
}
.prod-categories-card .heading-07 {
  font-size: 16px;
}
.heading-04 {
  font-size: 22px;
}
@media (min-width: 576px) {
  .prod-categories-card {
    height: 140px;
    width: 150px;
  }
  .prod-categories-card img {
    width: 48px;
    height: 48px;
  }
  .prod-categories-card .heading-07 {
    font-size: 20px;
  }
  .heading-04 {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .prod-categories-card {
    height: 160px;
    width: 184px;
  }
}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes moveToRightInfinite {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 100vw 100%;
  }
}
.top-products {
  margin-bottom: 40px;
  position: relative;
  height: /*594px*/ 310px;
  background-image: url('../../assets/images/compress_easter_home-top-products_1350x466.webp');
  background-size: cover;
  background-position: bottom;
  animation: moveToRightInfinite 50s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -64px;
  overflow: hidden;
}
.page-title {
  font-size: 24px;
  font-family: Raleway;
  font-weight: 500;
  color: var(--neutral-8);
}

@media (min-width: 768px) {
  .top-products {
    height: /*594px*/ 380px;
    margin-bottom: 80px;
  }
}

@media (min-height: 870px) and (max-height: 1240px) {
  .top-products .page-title {
    font-size: 3.5vh;
    line-height: 45px;
  }
  .top-products {
    height: 45vh;
    margin-bottom: 80px;
  }
}
@media (min-height: 1240px) {
  .top-products .page-title {
    font-size: 42px;
    line-height: 45px;
  }
  .top-products {
    height: 594px;
    margin-bottom: 80px;
  }
}

.top-products .bg {
  background: rgb(255 255 255 / 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 576px) {
  .top-products .bg {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.95) 35.67%,
      rgba(255, 255, 255, 0.95) 64.03%,
      rgba(255, 255, 255, 0.2) 100%
    );
  }
}

.top-products .content {
  z-index: 1;
}

.newsletter.card .card-body {
  display: flex;
  padding: 32px 5vw;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
}
.newsletter.card {
  border-radius: 16px;
  background: linear-gradient(100deg, #d6366c 0%, #802041 100%);
  box-shadow: 10px 10px 40px -10px rgba(214, 54, 108, 0.5);
  min-height: 254px;
}

@media only screen and (min-width: 992px) {
  .newsletter.card {
    position: absolute;
    top: 50%;
    left: 32.5%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 1200px) {
  .newsletter.card {
    width: 100%;
    max-width: 600px;
  }
}

.locations-section .location.card .icon:hover {
  background: var(--primary-6);
  box-shadow: 0px 0px 20px 0px rgba(214, 54, 108, 0.3);
}

.locations-section .location.card .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--primary-5);
  box-shadow: 0px 0px 20px 0px rgba(214, 54, 108, 0.3);
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: -22px;
  transition: 0.2s;
  cursor: pointer;
}

.locations-section .location.card .card-body .contact-box {
  border: 1px solid transparent;
  border-width: 1px 0px 1px 0px;
  border-image: linear-gradient(
    90deg,
    rgba(225, 225, 225, 0) 0%,
    rgba(227, 227, 227, 0.6) 9.37%,
    #95a6ac 48.44%,
    rgba(218, 218, 218, 0.6) 90.1%,
    rgba(223, 223, 223, 0) 100%
  );
  border-image-slice: 1;
  display: block;
  width: 100%;
  padding: 12px 0px 12px 0px;
}

.locations-section .location.card .card-body .name {
  color: var(--neutral-7);
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: 4px;
  text-transform: uppercase;
}
.locations-section .location.card .card-body {
  display: flex;
  padding: 32px 32px 40px 32px;
  flex-direction: column;
  align-items: center;
}
.locations-section .location.card {
  border-radius: 8px;
  background: var(--neutral-1);
  box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid transparent;
  height: 100%;
}
.locations-section {
  content-visibility: auto;
  margin-top: 8px;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
    90deg,
    rgba(223, 210, 187, 0) 0%,
    rgba(223, 210, 187, 0.6) 9.37%,
    #bfa577 48.44%,
    rgba(223, 210, 187, 0.6) 90.1%,
    rgba(223, 210, 187, 0) 100%
  );
  border-image-slice: 1;
  border-left: none;
  border-right: none;
  border-bottom: none;
  /* background-image: url('../../assets/images/home-map-locations.webp'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 72vh /*670px*/;
  position: relative;
  overflow: hidden;
}

.locations-section .rounded-bg {
  position: absolute;
  bottom: 0;
}

.review-sections {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  content-visibility: auto;
}

.review.card .card-img-top {
  border-radius: 8px;
  object-fit: cover;
  position: absolute;
  left: 0;
  margin-left: auto;
  right: 0;
  margin-right: auto;
  top: -30%;
  z-index: 1;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15);
}

.review.card .card-body {
  border-radius: 16px;
  border: 1px solid var(--secondary-3);
  background: var(--neutrak-1);
  padding: 40px 20px;
}

@media only screen and (min-width: 576px) {
  .review.card .card-body {
    border-radius: 16px;
    border: 1px solid var(--secondary-3);
    background: var(--neutrak-1);
    padding: 78px 54.5px;
  }
}

.review.card {
  border: transparent;
  position: relative;
  border-radius: 12px;
  margin-top: 64px;
  margin-left: 12px;
  margin-right: 12px;
}

.recent-articles-section .article .card-img-top {
  object-fit: cover;
  border-radius: 10px; /* will have no effect */
  border: 1px solid var(--whitetint-3);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
}
.recent-articles-section .article .card-img-box {
  display: block;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  transition: 0.2s;
  max-width: 85%;
  width: 100%;
}

.recent-articles-section .article .date-box {
  background: linear-gradient(138.81deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  backdrop-filter: blur(40px);
  border-width: 1px 1px 0px 0px;
  border-color: var(--whitetint-3);
  padding: 10px;
  border-radius: 0px 11px 0px 11px;
  position: absolute;
  bottom: 1px;
  left: 1px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: white;
}
.recent-articles-section .article.card {
  background-color: transparent;
  border: transparent;
  position: relative;
  border-radius: 12px;
}
.recent-articles-section .article .card-body {
  padding: 0px 21px 21px 21px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: var(--whitetint-2, rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(10px);
  border: 1px solid var(--whitetint-3);
  border-image-slice: 1;
  border-radius: 12px;
  height: 100%;
}
@media only screen and (max-width: 576px) {
  .recent-articles-section .article .card-body .heading-06 {
    font-size: 18px;
  }
  .recent-articles-section .article .card-body .paragraph-02 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 992px) {
  .mb-articles {
    margin-bottom: 150px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .recent-articles-section .container-xl {
    max-width: 70vw;
  }
}

@media only screen and (max-width: 768px) {
  .recent-articles-section .article .card-img-box {
    top: -120px;
    height: 200px;
  }
  .recent-articles-section .article .card-body {
    padding-top: 90px;
  }
}
@media only screen and (min-width: 768px) {
  .recent-articles-section .article .card-img-box {
    max-height: 257px;
    height: 100%;
  }
  .recent-articles-section .article .card-body {
    height: 379px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .recent-articles-section .article .article-mobile-height {
    min-height: 180px;
  }
  .recent-articles-section .article .card-img-box {
    top: -120px;
  }
}
@media only screen and (min-width: 992px) {
  .recent-articles-section .article .card-img-box {
    top: -120px;
  }
}
/*hover effect*/
@media only screen and (min-width: 1024px) {
  .recent-articles-section .article .other-info {
    height: 60px;
    overflow: hidden;
    transition: 0.2s;
  }
  .recent-articles-section .article:hover .other-info {
    height: 90px;
  }
  .recent-articles-section .article:hover .card-img-box {
    top: -75px;
  }
  .recent-articles-section .article .card-img-box {
    top: -45px;
  }
}
/*end hover effect*/

.recent-articles-section .article a.go-to-article-btn:hover img {
  filter: invert(29%) sepia(82%) saturate(1706%) hue-rotate(315deg) brightness(88%) contrast(90%);
}
.recent-articles-section .article a.go-to-article-btn:hover {
  box-shadow: 0px 4px 10px 0px #00000026;
  background-color: #ffffff;
}
.recent-articles-section .article a.go-to-article-btn img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(270deg) brightness(106%) contrast(101%);
  height: 22px;
}
.recent-articles-section .article a.go-to-article-btn {
  border: 1px solid var(--whitetint-7) !important;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  transition: 0.2s;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recent-articles-section .title-border {
  border: 1px solid transparent;
  border-width: 1px 0px 0px 0px;
  border-image: linear-gradient(
    90deg,
    rgba(223, 210, 187, 0) 0%,
    rgba(223, 210, 187, 0.6) 9.37%,
    #bfa577 48.44%,
    rgba(223, 210, 187, 0.6) 90.1%,
    rgba(223, 210, 187, 0) 100%
  );
  border-image-slice: 1;
  border-radius: 10px;
}
.recent-articles-section {
  margin-top: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  content-visibility: auto;
  height: 100%;
  padding: 8vw 0px;
  background-image: url('../../assets/images/recent-articles-bg.webp');
}

@media (max-width: 576px) {
  .heading-01 {
    font-size: 28px;
  }
}

.recommended-products-section {
  padding-top: 8px;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
    90deg,
    rgba(223, 210, 187, 0) 0%,
    rgba(223, 210, 187, 0.6) 9.37%,
    #bfa577 48.44%,
    rgba(223, 210, 187, 0.6) 90.1%,
    rgba(223, 210, 187, 0) 100%
  );
  border-image-slice: 1;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1280px;
  }
}
</style>
