import moment from 'moment';
import Swal from 'sweetalert2';
import store from '../store/index';

const utils = {
  DisplayProductPrice(price) {
    if (Number(price) > 0) return Number(price).toFixed(2).replace(/\.0+$/, '');
    return '';
  },
  DisplayCalculatedProductPrice(price, amount) {
    if (amount <= 0) {
      return Number(price).toFixed(2).replace(/\.0+$/, '');
    }
    return Number(price * amount)
      .toFixed(2)
      .replace(/\.0+$/, '');
  },
  DisplayAvatar(imageBase64) {
    if (!imageBase64) {
      // eslint-disable-next-line global-require
      return require('@/assets/images/no-avatar-review.svg');
    }
    return imageBase64;
  },
  GetImageFormat(imageBase64) {
    if (!imageBase64) {
      // eslint-disable-next-line global-require
      return require('@/assets/images/default-thumbnail.svg');
    }
    return imageBase64;
  },
  ShowProductImage(imageBase64) {
    if (!imageBase64) {
      // eslint-disable-next-line global-require
      return require('@/assets/images/default-thumbnail.svg');
    }
    return imageBase64;
  },
  ShowArticleImage(imageBase64) {
    if (!imageBase64) {
      // eslint-disable-next-line global-require
      return require('@/assets/images/default-thumbnail.svg');
    }
    return imageBase64;
  },
  InputImage(item) {
    // eslint-disable-next-line no-undef, no-restricted-globals
    const fileInput = event.target;
    const file = fileInput.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      // eslint-disable-next-line vue/no-mutating-props, no-param-reassign
      item.ImgBase64 = reader.result;
      fileInput.value = '';
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  },
  Ellipsify(str, length) {
    if (str && str.length > length) {
      return `${str.substring(0, length)}...`;
    }

    return str;
  },
  EllipsifyWords(str, length) {
    const words = str.split(' ');
    if (words.length > length) {
      return `${words.slice(0, length).join(' ')}...`;
    }
    return str;
  },
  FormatDayMonthYear(value) {
    if (!value) return null;
    return moment(value).format('DD.MM.YYYY');
  },
  StringToSlug(str) {
    // eslint-disable-next-line no-param-reassign
    str = str.toLowerCase();
    const diacriticsMap = {
      ă: 'a',
      â: 'a',
      î: 'i',
      ș: 's',
      ț: 't',
    };

    // eslint-disable-next-line no-param-reassign
    str = str.replace(/[ăâîșț]/g, (match) => diacriticsMap[match]);
    return str.toLowerCase().replace(/[\W_]+/g, '-');
  },
  ToastNotify(Type, Title, Timer) {
    // eslint-disable-next-line no-param-reassign
    if (!Timer) Timer = 3000;
    Swal.fire({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: Timer,
      timerProgressBar: true,
      icon: Type,
      title: Title,
    });
  },
  ApiNameByCurrentUser(name) {
    if (store.state.auth.user) return name;
    return `${name}Guest`;
  },
  ShowDefaultImage(e) {
    // eslint-disable-next-line global-require
    e.target.src = require('@/assets/images/default-thumbnail.svg');
  },
};

export default utils;
